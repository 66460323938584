.material-symbols-outlined {
  font-variation-settings: "FILL" 0,
  "wght" 0,
  "GRAD" 250,
  "opsz" 48;
}

.logo {
  position: absolute;
  height: 33px;
  left: 12px;
  cursor: pointer;
  fill: white;
}

.navBar {
  position: fixed;
  background: linear-gradient(to right bottom, #000000, #000000);
  height: 60px;
}
