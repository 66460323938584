.modal-import-inies {
    border-radius: 8px;
}

.modal-import-inies::-webkit-scrollbar {
    position: relative;
    width: 8px;
}

.modal-import-inies::-webkit-scrollbar-track {
    background: transparent;
}

.modal-import-inies::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}

.modal-import-inies::-webkit-scrollbar-thumb:hover {
    background: #555;
}
