.menu-item-viewer:hover {
  color: orange;
}

.menu-item-viewer {
  color: #fff;
}

.viewer-snackbar {
  position: "absolute";
  background-color: #333;
  color: #fff;
  border-radius: 3;
  padding: 2px;
}
