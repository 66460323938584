.illuminated-button {
    position: relative;
    overflow: hidden;
}

.illuminated-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0;
    transition: left 0.5s,
    opacity 0.5s;
}

.illuminated-button:hover::before {
    left: 100%;
    opacity: 1;
}
