.partial-border::after {
    content: "";
    width: 1px;
    height: 82%;
    background: #e4e1e1;
    position: absolute;
    right: -24px;
    bottom: 0;
    top: 164px;
}
