.table-digital-model::-webkit-scrollbar {
    width: 10px;
}

.table-digital-model::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.table-digital-model::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
}

.table-digital-model::-webkit-scrollbar-thumb:hover {
    background: #555;
}
